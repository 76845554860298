

window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
	   
	}
}



